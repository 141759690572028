<style scoped>
  .tab-label {
    color: white
  }
</style>

<template>
	<div id="about_page">
		<header class="mui-bar mui-bar-nav">
			<a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"></a>
			<h1 class="mui-title">XP inspector version: {{version}}</h1>
		</header>
		<div class="mui-content">
      <div class="mui-scroll-wrapper">
  			<div class="mui-text-center">
  				<p id="version" class="mui-text-center">

  				</p>
  				<!-- <svg class="icon mui-pull-left" aria-hidden="true">
  					<use xlink:href="#icon--yinhangqia"></use>
  				</svg> -->
  				<br>
  				<img src="@/assets/logo.png" style="width: 10rem; border-radius: 2rem">
  				<br>
  				<p>1. Allows all tasks be achived.</p>
  				<p>2. Always show houses.</p>
  				<p>3. Display assignees</p>
  			</div>
      </div>
		</div>
    <nav class="mui-bar mui-bar-tab wrapper" v-if="false">
      <button type="button" class="tab-item mui-btn mui-btn-red function" v-on:tap='tap_buildInspectionResults()'>
        <span class="tab-label">buildInspectionResults</span>
      </button>
      <button type="button" class="tab-item mui-btn mui-btn-green function" v-on:tap='tap_buildRunningInspectionResults()'>
        <span class="tab-label">buildRunningInspectionResults</span>
      </button>
    </nav>

	</div>
</template>

<script>
import axios from 'axios';
import { version } from '/package.json'

export default {
  name: "AboutUs",
  computed: {
    testing() {
      return window.appConfig.testing
    },
    version() { return version }
  },
  methods: {
    async tap_buildInspectionResults() {
      await axios.get('task/buildInspectionResults')
    },
    async tap_buildRunningInspectionResults() {
      await axios.get('task/buildRunningInspectionResults')
    },
  }
}
</script>